(function($){
	/* Get accurate Viewport sizing (no jQuery) */
    var viewportwidth;
    var viewportheight;

    function getWindowSize(){
        if (typeof window.innerWidth != 'undefined'){
            viewportwidth = window.innerWidth,
            viewportheight = window.innerHeight
        }else if (typeof document.documentElement != 'undefined' && typeof document.documentElement.clientWidth != 'undefined' && document.documentElement.clientWidth != 0){
            viewportwidth = document.documentElement.clientWidth,
            viewportheight = document.documentElement.clientHeight
        }else{
            viewportwidth = document.getElementsByTagName('body')[0].clientWidth,
            viewportheight = document.getElementsByTagName('body')[0].clientHeight
        }
    }
    
     function megaMenu(){
        
        var department = (function () {
            var department = null;
            $.ajax({
                'async': false,
                'global': false,
//                'url': 'https://store.beverlysacehardware.com/inet/storefront/get_data.php?format=json&inet_department_tree=Y&inet',
                'url': 'https://www.beverlysacehardware.com/inet/storefront/get_data.php?format=json&inet_department_tree=Y&inet',
                'dataType': "JSON",
                'success': function (department) {
                    JSON = department;
                }
            });
            return JSON;
        })(); 
        
        var custommenu = (function () {
            var custommenu = null;
            $.ajax({
                'async': false,
                'global': false,
//                'url': 'https://store.beverlysacehardware.com/inet/storefront/get_data.php?format=json&inet_custom_menu=Y&inet',
                'url': 'https://www.beverlysacehardware.com/inet/storefront/get_data.php?format=json&inet_custom_menu=Y&inet',
                'dataType': "JSON",
                'success': function (custommenu) {
                    JSON = custommenu;
                }
            });
            return JSON;
        })(); 
        
        var menuString = '<ul class="nav-menu" id="site-menu">';
         
        menuString += '<li class="menu-item menu-item-has-children" data-level="0" id="nav-departments">';
        menuString += '<a href="/departments" class="list-link"><strong>Departments</strong></a>';
         
        if(department.inet_department_tree != null){
            
            var totalLength = department.inet_department_tree.length - 1;
//            console.log(totalLength);

            menuString += '<span class="arrow"></span>';
            menuString += '<ul class="sub-menu" id="department-list">';
            
            var mainlength = 1;
            
            $.each(department.inet_department_tree, function() {
                
                var currentlength = mainlength + 1,
                    mainMenuName = this.name.toLowerCase(),
                    mainMenuUrl = this.url,
                    mainMenuIdRaw = mainMenuName.replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '-'),
                    mainMenuId = mainMenuIdRaw.replace(/ /g,''),
                    mainMenuClassArray = ['menu-item','megamenu-item'],
                    mainMenuSeparator = ' ';

                if(this.children != null){
                    mainMenuClassArray.push('menu-item-has-children');
                }

                var mainMenuLiClass = mainMenuClassArray.join(mainMenuSeparator); 

                menuString += '<li class="' + mainMenuLiClass + '" data-level="1" id="nav-' + mainMenuId + '">';
                menuString += '<a class="list-link" href="' + mainMenuUrl + '">' + mainMenuName + '</a>';


                if(this.children != null){
                    menuString += '<span class="arrow"></span>';
                    menuString += '<ul class="sub-menu sub-menu-container">';
                    menuString += '<li class="menu-item-title-link">' + mainMenuName + '</li>';
                    menuString += '<li class="menu-image"></li>';
                    
                    $.each(this.children,function(i){

                        var childlength = i + 1,
                            childMenuName = this.name.toLowerCase(),
                            childMenuUrl = this.url,
                            childIdRaw = childMenuName.replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '-'),
                            childMenuId = childIdRaw.replace(/ /g,''),
                            childMenuClassArray = ['menu-item','level-2'],
                            childMenuSeparator = ' ';
                        
                        if(childlength >= totalLength){
                            childMenuClassArray.push('hidden-desktop');
                        }
                        
                        var childMenuLiClass = childMenuClassArray.join(childMenuSeparator); 

                        menuString += '<li class="' + childMenuLiClass + '" data-level="2" id="nav-' + childMenuId + '">';

                        menuString += '<a class="list-link" href="' + childMenuUrl + '">' + childMenuName + '</a>';


                        if(this.children != null){
                            menuString += '<span class="arrow"></span>';
                            menuString += '<ul class="sub-menu sub-menu-container">';
                            menuString += '<li class="menu-item-title-link"><a href="' + childMenuUrl + '">' + childMenuName + '</a></li>';

                            $.each(this.children,function(i){

                                var grandchildlength = i + 1,
                                    grandchildMenuName = this.name.toLowerCase(),
                                    grandchildMenuUrl = this.url,
                                    grandchildIdRaw = grandchildMenuName.replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '-'),
                                    grandchildMenuId = grandchildIdRaw.replace(/ /g,''),
                                    grandchildMenuClassArray = ['menu-item','level-3'],
                                    grandchildMenuSeparator = ' ';
                                
                                if(grandchildlength >= totalLength){
                                    grandchildMenuClassArray.push('hidden-desktop');
                                }

                                var grandchildMenuLiClass = grandchildMenuClassArray.join(grandchildMenuSeparator); 

                                menuString += '<li class="' + grandchildMenuLiClass + '" data-level="3" id="nav-' + grandchildMenuId + '">';

                                menuString += '<a class="list-link" href="' + grandchildMenuUrl + '">' + grandchildMenuName + '</a>';
                                menuString += '</li>';
                            });

                            menuString += '<li class="menu-item view-all"><a href="' + childMenuUrl + '">View All</a></li>';

                            menuString += '</ul>';
                        }


                        menuString += '</li>';
                    });

                    menuString += '<li class="menu-item view-all"><a href="' + mainMenuUrl + '">View All</a></li>';
                    menuString += '</ul>';
                }

                menuString += '</li>';
                
                mainlength = mainlength + 1;

            });

            menuString += '</ul>';
        }
         menuString += '</li>';
        
        $.each(custommenu.inet_custom_menu, function(name,url) {
            
            var customMenuName = name,
                customMenuUrl = url,
                customMenuIdRaw = customMenuName.replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '-'),
                customMenuId = customMenuIdRaw.replace(/ /g,'');

            menuString += '<li class="menu-item megamenu-item" data-level="1" id="nav-' + customMenuId + '">';
            menuString += '<a class="list-link" href="' + customMenuUrl + '">' + customMenuName + '</a>';   
            menuString += '</li>';
            
        });
         
         menuString += '</ul>';
         
        $("#inet_sitenav").append(menuString);
    }
    
    function arrowClicks(){
        $('.nav-menu li .arrow').on('click',function(e) {
            e.preventDefault();
            
            var parentId = $(this).parents('.menu-item').attr('data-level');
            var parentLevel = $(this).parent().attr('id');
            
            $(this).next().addClass('active');
            $(this).parent().addClass('active');
            $(this).parent().siblings().hide();
        });
        $('.sidr__menu-back').on('click',function(){
            var menuCloseId = $( this ).data('sidr-menu');
            
            //remove active class from menu containing UL
            $('#'+menuCloseId).removeClass('active');
            
            //remove active class from parent LI
            $('#'+menuCloseId).parent().removeClass('active');

            //show the remaining LI siblings
            $('#'+menuCloseId).parent().siblings('.menu-item').show();
            
            //if there is a back menu link, show it
            if($('#'+menuCloseId).parent().siblings('.sidr__menu-back').length){
                $('#'+menuCloseId).parent().siblings('.sidr__menu-back').show();
            }
        });
    }

	/* Document Ready */
	$(document).ready(function() {
		getWindowSize();
        
        megaMenu();
        
        $('.slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            cssEase: 'linear',
            fade: true,
            adaptiveHeight: true
        });
        
        //Sidr
        $('#mobile-trigger').sidr({
            side: 'left',
            name: 'sidr',
            displace: false,
            source : function() {
                
                var sidrHtml;

                sidrHtml = '<div id="mobile-menu">';
                sidrHtml += '<span class="sidr__close" aria-label="Close Menu"></span>';
                sidrHtml += '<div class="sidr__inner">' + $('.main-navigation').html() + '</div>';
                sidrHtml += '</div>';
                
                return sidrHtml;
            },
            onOpen: function() {
                //alert('test');
                $( 'body' ).prepend( '<div class="sidr-overlay"></div>' );
                $('#mobile-trigger').hide();
                $('#mobile-close').show();
                
                $('#masthead').addClass('mobile');
                
                $('.sidr__inner').find('.sub-menu').each(function() {
                    var menuId = $(this).parent().attr('id');
                    var parentLink = $(this).prevAll('a').attr('href');
//                    var parentText = $(this).prevAll('a').html();
                    var parentText = $(this).prevAll('.list-link').html();
                    $(this).attr('id','sidr-'+menuId).prepend('<li class="sidr__menu-back" data-sidr-menu="sidr-'+menuId+'">'+parentText+'</li>');
                });
            },
            onOpenEnd: function(){
                $( 'body' ).css({
                    "height": "100%",
                    "overflow": "hidden"
                });
                arrowClicks();
            },
            onClose: function(){
                $( '.sidr-overlay' ).remove();
                $('#mobile-trigger').show();
                $('#mobile-close').hide();
                $('#masthead').removeClass('mobile');
            },
            onCloseEnd: function(){
                $( 'body' ).css({
                    "height": "auto",
                    "overflow": "visible"
                });
                $('.sidr__inner').find('.sub-menu').each(function() {
                    $(this).find('.sidr__menu-back').remove();
                    $(this).find('.sidr__sub-menu-parent').remove();
                });
            }
        });

        $('.sidr__close').click(function() {
            $.sidr('close','sidr');
            $('.nav-menu li .arrow').unbind();
        });
        
        
        if($( '#home_page' ).length){
            $('body').addClass('homepage');
        }
        
        if($( '.store_products_list' ).length){
            $( '.card' ).each(function() {
                
                var button = $( this ).find( '.product_form_buttons' );
                
                if (button.is(':empty')){
                    button.append('<div class="store_product_cart mb-2"><button class="btn btn-secondary btn-block" aria-disabled="true" disabled>Add to Cart</button></div>');
                }
            });
        }
        
	});

	$(window).on("resize", function() {
		getWindowSize();
        
        if(viewportwidth >= 1200){
            $.sidr('close','sidr');
            $('.nav-menu li .arrow').unbind();
        }
    
	});

})(jQuery);